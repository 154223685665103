<template>
    <div class="video">
        <template v-if="enterpriseinfo && enterpriseinfo.id">            
            <vipTop :enterpriseinfo="enterpriseinfo" select="2" @ourShop="ourShop" @openMessage="openMessage" v-if="enterpriseinfo.vipLeve!=0"></vipTop>
            <top :enterpriseinfo="enterpriseinfo" select="2" @ourShop="ourShop" @openMessage="openMessage" v-else></top>
        </template>
        <div :style="{width: this.$oucy.pageWidth+'px', display: 'inline-block'}">
            <div>
                <div class=" m-t-50">
                    <el-row>
                        <el-col :span="5">
                            <div class="videoLeft">
                                <div class="com_card">
                                    <p v-if="enterpriseinfo">{{enterpriseinfo.enterpriseName}}</p>
                                    <div class="df-jc-s-a f12">
                                        <div class="pointer" @click="$oucy.go('/Shop?enterpriseId='+enterpriseinfo.id, null)"><img src="@/assets/images/product/dianpu.png" alt="">
                                            <div>商铺首页</div>
                                        </div>
                                        <div class="pointer" @click="openMessage()"><img src="@/assets/images/product/dianpu.png" alt="">
                                            <div>在线联系</div>
                                        </div>
                                    </div>
                                </div>
                                <table class="store_kind">
                                    <th>视频分类</th>
                                    <tr v-for="(v,i) of byLtUserAuthAndClassifyCategoryList" :key="i">
                                        <td>
                                            <el-button type="text" :class="{selsct:v.id==queryFurnitureLibrary2.enterpriseVideoAlbumId}" @click="setClassify(v)">{{v.videoAlbumName}}</el-button>
                                        </td>
                                    </tr>
                                </table>
                                <table class="customer_service">
                                    <th>店内客服</th>
                                    <tr v-for="(v,i) of enterpriseCustomer" @click="openImMessage(v)" :key="i">
                                        <td class="df-ai-c p-l-15">
                                            <img :src="$oucy.ossUrl+v.detailAvatar" v-if="v.detailAvatar">
                                            <img src="../../assets/icon/customer_service_icon.png" v-else>
                                            <el-button type="text" class="m-l-10 line-1" style="width: 120px;" :title="v.customerNickname||v.detailNickname">
                                                {{v.customerNickname||v.detailNickname}}</el-button>
                                        </td>
                                    </tr>
                                </table>
                                <table class="sales_rank " v-if="spuRankingWeight">
                                    <th class="text-center">
                                        <div style="width:210px;margin-top:2px; "><img src="../../assets/icon/crown.png"></div>
                                        -销量排行-
                                    </th>
                                    <tr v-for="(v,i) of spuRankingWeight" @click="$oucy.go('/product?spuId='+v.id)">
                                        <td><img :src="$oucy.ossUrl+ v.cover" style="width: 188px;height: 188px;">
                                            <div class="goods_name line-1"><span>{{v.spuName}}</span></div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </el-col>
                        <el-col :span="19">
                            <div class="videoRight">
                                <div class="videoAlbumName">{{queryFurnitureLibrary2.videoAlbumName}}</div>
                                <div class="boxs" v-if="queryFurnitureLibraryObj2 && queryFurnitureLibraryObj2.content&& queryFurnitureLibraryObj2.content.length">
                                    <div class="box" v-for="(v,i) in queryFurnitureLibraryObj2.content" :key="i">
                                        <div class="item pointer" @click="openVideoPlay(v,i)">
                                            <div class="itemCover">
                                                <!-- <img :src="$oucy.ossUrl+v.itemCover" alt=""> -->
                                                <div class="bg"></div>
                                                <el-image style="width: 100%; height: 215px" :src="$oucy.ossUrl+v.itemCover" fit="cover"></el-image>
                                                <i class="el-icon-video-play"></i>
                                            </div>
                                            <div class="line-2 itemInfo">{{v.itemInfo}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-50 text-center c-2" v-else>暂无数据</div>
                                <div class="pagination">
                                    <el-pagination v-if="queryFurnitureLibraryObj2.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="queryFurnitureLibraryObj2.size" :total="queryFurnitureLibraryObj2.totalElements" @size-change="sizeChange2" @current-change="currentChange2" :page-sizes="pageSizes" @prev-click="currentChange2" @next-click="currentChange2">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <div class="text-center m-b-50">
            <img src="@/assets/images/product/logo.png" class="m-r-15">
            <img src="@/assets/images/product/VIPban.png" v-if="enterpriseinfo.vipLeve!=0">
        </div>
        <videoPlay ref="videoPlay"></videoPlay>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { localSet, localGet, localDel } from "@/store/store";
import { spu, furnitureClassify, enterpriseinfo, adEnterprise, cardEnterprise, enterprisevideoalbum } from "@/service"
import videoPlay from "@/components/videoPlay.vue"
import vipTop from "./components/vipTop.vue"
import top from "./components/top.vue"
export default {
    name: "product",
    components: {
        videoPlay,
        vipTop,
        top,
    },
    data() {
        return {
            pageWidth: oucy.pageWidth,
            bannerHeight: 1000,
            bannerImgHeight: 560,
            bannerImgWidth: 1920,
            screenWidth: 0,
            setSizeCount: 0,
            setSizeTimId: null,

            checkAll: '',
            num: 1,
            num2: 1,
            activeName: 'first',
            labelPosition: 'left',
            isChoose: false,
            isPhone: false,
            isActive: '0',
            dialogVisible: false,
            text: '',
            textarea: '',
            drawer: false,
            tabPosition: 'left',
            list: [1, 2, 3],
            size: '660px',
            goodsList: [],
            srcList: [],
            // 头部
            spuName: '',
            searchClassify: '1',
            value: '',
            levelList: [],
            spuId: null,
            // 详情
            detail: {},
            // 选中列表
            select: {},
            // 店内分类列表
            byLtUserAuthAndClassifyCategoryList: [],
            carousel: [],

            queryFurnitureLibraryObj: {},
            // 请求对象
            queryFurnitureLibrary: {
                category: 0,
                limit: 200,
                start: 0,
                globalAttrOption: null,
                globalClassId: null,
                goodSort: 0,
                priceMax: null,
                priceMin: null,
            },

            queryFurnitureLibraryObj2: {},
            // 请求对象
            queryFurnitureLibrary2: {
                limit: 20,
                start: 0,
                itemIsShelves: true, //已上架产品
                enterpriseVideoAlbumId: null,
                videoAlbumName: '全部分类',
                itemInfo: null,

            },
            pageSizes: [20, 40, 100],
            type: 1,
            list: [],
            hotSpu: [],
            recommendSpu: [],
            newSpu: [],
            carouselList: [],
            enterpriseinfo: {},
            hasSearch: false,
            searchSpu: [],
            navType: 'home', //spu home
            spuRankingWeight: [],
            enterpriseCustomer: [],
            loginUser: {},
        }

    },
    mounted() {
        this.enterpriseId = this.$route.query.enterpriseId
        // 查询企业
        this.findEnterpriseByLtUserAuthId()
        this.setSize();

        window.onresize = () => {
            this.setSize()
        };

        if (this.$route.query.navType) {
            this.navType = this.$route.query.navType
        }
        if (this.$route.query.enterpriseVideoAlbumId) {
            this.queryFurnitureLibrary2.enterpriseVideoAlbumId = this.$route.query.enterpriseVideoAlbumId
        }
        if (this.$route.query.spuName) {
            this.spuName = this.$route.query.spuName
            // 查询商品
            // this.findAllFurnitureSpu()
            this.hasSearch = true
        } else {
            // this.queryEnterpriseHomepageSpu()
            this.hasSearch = false
        }
        // 查询企业轮播图
        this.queryAdEnterprises()
        // 查询企业视频分类
        this.getAllEnterpriseVideoAlbumsClient()

        this.queryAllVideoItemForPage()
        this.getSpuRankingWeight()

    },
    watch: {
        $route: {
            handler(to, from) {
                this.getBreadcrumb()
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        // 切换页数
        sizeChange2: function(pageSize) {
            const me = this;
            me.queryFurnitureLibrary2.limit = pageSize;
            me.queryAllVideoItemForPage();
        },
        // 切换页码
        currentChange2: function(current) {
            const me = this;
            me.queryFurnitureLibrary2.start = current - 1;
            me.queryAllVideoItemForPage();
        },
        // setSize: function() {
        //     // 通过浏览器宽度(图片宽度)计算高度
        //     this.screenWidth = window.innerWidth;
        //     this.bannerHeight = (this.bannerImgHeight / this.bannerImgWidth) * this.screenWidth
        // },
        setSize() {
            //通过浏览器宽度(图片宽度)计算高度
            if (this.$refs && this.$refs.adImg) {
                this.bannerHeight = this.$refs.adImg.height
            } else {
                this.screenWidth = window.innerWidth;
                this.bannerHeight = (this.bannerImgHeight / this.bannerImgWidth) * this.screenWidth
                this.setSizeTimId && clearTimeout(this.setSizeTimId)
                this.setSizeTimId = setTimeout(() => {
                    ++this.setSizeCount
                    if (this.setSizeCount < 100) {
                        this.setSize();
                    }
                }, 1000)
            }
        },

        // 获取图片大小
        getImgSize(url) {
            let img = new Image();
            img.onload = () => {
                this.bannerImgHeight = img.height
                this.bannerImgWidth = img.width
                this.setSize();
            };
            img.src = url
        },
        // 获取图片大小
        getImgSize2(url) {
            let img = new Image();
            img.onload = () => {
                this.bannerHeight2 = img.height * (img.width / 1280 - 1)
                console.log('this.bannerHeight2', this.bannerHeight2)
                // this.bannerImgWidth = 
            };
            img.src = url
        },

        handleClose(c) {
            c()
        },
        handleCheckAllChange() {},
        goSearch() {
            if (!this.spuName) {
                this.$message('请输入你要搜索的内容')
            } else {
                this.findAllFurnitureSpu()
                this.hasSearch = true
            }
        },

        getBreadcrumb() {
            //$route.matched是一个数组 包含当前路由所有嵌套路径的路由记录
            let matched = this.$route.matched.filter(item => item.name)
            const first = matched[0];
            if (first && first.name !== '首页') {
                matched = [{ path: '/', meta: { title: '首页' } }].concat(matched)
            }
            this.levelList = matched
            console.log(this.levelList)
        },

        /**
         * 查询企业视频分类
         * @return {[type]} [description]
         */
        getAllEnterpriseVideoAlbumsClient() {
            enterprisevideoalbum.getAllEnterpriseVideoAlbumsClient({ enterpriseId: this.enterpriseId }).then(res => {
                this.byLtUserAuthAndClassifyCategoryList = [{videoAlbumName:'全部分类',id:null},...res]
            })
        },


        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.queryFurnitureLibrary.limit = pageSize;
            me.findAllFurnitureSpu();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.queryFurnitureLibrary.start = current - 1;
            me.findAllFurnitureSpu();
        },
        // 获取店铺首页商品集
        queryEnterpriseHomepageSpu() {
            enterpriseinfo.queryEnterpriseHomepageSpu({ enterpriseId: this.enterpriseId }).then(res => {
                // this.list.push(...res.hotSpu, ...res.newSpu)
                this.hotSpu = res.hotSpu
                this.newSpu = res.newSpu
                this.recommendSpu = res.recommendSpu
            })
        },
        // 查询企业轮播图
        queryAdEnterprises() {
            adEnterprise.queryAdEnterprises({ adImgFlag: 0, enterpriseId: this.enterpriseId }).then(res => {
                if (res) {
                    this.carouselList = res
                    this.getImgSize(this.$oucy.ossUrl + this.carouselList[0].adImg)
                    this.getImgSize2(this.$oucy.ossUrl + this.carouselList[0].adImg)

                }
            })
        },


        // 查询企业
        findEnterpriseByLtUserAuthId() {
            enterpriseinfo.findEnterpriseByLtUserAuthId({ enterpriseId: this.enterpriseId }).then(res => {
                this.enterpriseinfo = res
                this.$oucy.setTitle(this.enterpriseinfo.enterpriseShopName)
                if (this.enterpriseinfo.vipLeve ==0) {
                    // this.$oucy.replace(`/Shop?enterpriseId=${this.enterpriseId }`)
                }
                this.queryEnterpriseCustomer()
            })
        },
        // 查询商品
        findAllFurnitureSpu() {
            this.queryFurnitureLibrary.enterpriseId = this.enterpriseId
            this.queryFurnitureLibrary.spuName = this.spuName
            this.queryFurnitureLibrary.isShelves = true
            spu.findAllFurnitureSpu(this.queryFurnitureLibrary).then(res => {
                this.queryFurnitureLibraryObj = res
            })
        },
        changeSpuName() {
            if (!this.spuName) {
                this.queryEnterpriseHomepageSpu()
                this.hasSearch = false
            } else {

            }
        },
        // 排序
        setGoodSort(v) {
            this.queryFurnitureLibrary2.goodSort = v
            this.queryAllVideoItemForPage()
        },
        // 查询企业店铺下的所有视频
        queryAllVideoItemForPage() {
            this.queryFurnitureLibrary2.spuName = this.spuName
            this.queryFurnitureLibrary2.enterpriseId = this.enterpriseId
            enterprisevideoalbum.queryAllVideoItemForPage(this.queryFurnitureLibrary2).then(res => {
                this.queryFurnitureLibraryObj2 = res
            })
        },

        // 点左边分类触发
        setClassify(v) {
            if (this.queryFurnitureLibrary2.enterpriseVideoAlbumId == v.id) {
                return
                this.queryFurnitureLibrary2.enterpriseVideoAlbumId = null
                this.queryFurnitureLibrary2.videoAlbumName = '全部分类'

            } else {
                this.queryFurnitureLibrary2.enterpriseVideoAlbumId = v.id
                this.queryFurnitureLibrary2.videoAlbumName = v.videoAlbumName
            }
            this.queryFurnitureLibrary2.start = 0
            this.queryAllVideoItemForPage()
        },
        gotoCard() {
            cardEnterprise.getCardEnterpriseDetail({ id: this.enterpriseId }).then(res => {
                if (res) {
                    this.$oucy.go(`/enterpriseDetail?id=${this.enterpriseId}`)
                } else {
                    this.$alert('您还没有创建企业名片，请先使用移动端创建企业名片')
                }
            })
        },
        // 查询企业店铺下销量前5的产品
        getSpuRankingWeight() {
            this.queryFurnitureLibrary2.enterpriseId = this.enterpriseId
            spu.getSpuRankingWeight(this.queryFurnitureLibrary2).then(res => {
                this.spuRankingWeight = res && res.content ? res.content : null
            })
        },
        /**
         * 查询企业客服
         * @param  {[type]} v [description]
         * @return {[type]}   [description]
         */
        queryEnterpriseCustomer() {
            enterpriseinfo.queryEnterpriseCustomer({ enterpriseId: this.enterpriseId }).then(res => {
                if (res && res.length) {
                    this.enterpriseCustomer = res || []
                }
            })
        },
        openMessage() {
            console.log(111)
            this.enterpriseCustomer[0] && this.openImMessage(this.enterpriseCustomer[0])
        },
        /**
         * 打开聊天框
         * @param  {[type]} targetUserId [description]
         * @return {[type]}              [description]
         */
        openImMessage(v) {
            this.loginUser = localGet('loginUser')
            if (this.loginUser && this.loginUser.id) {
                this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, { targetUserId: v.id });
            } else {
                this.$alert('请先登录', '提示')
            }
        },
        ourShop(v) {
            this.navType = 'spu'
            this.queryFurnitureLibrary2.start = 0
            this.queryFurnitureLibrary2.itemInfo = v.keyword
            // 查询商品
            this.queryAllVideoItemForPage()
            console.log(v)
        },
        openVideoPlay(v, i) {
            if (this.queryFurnitureLibraryObj2.content.length == 1) {

                let url = this.$oucy.ossUrlPrefixVideo+'/' + v.itemUrl
                this.$refs.videoPlay.openVideoPlay(url)
                console.log(url)
            } else {
                // https://aliyunoss.lesoujia.com/lsj_video/1642738610812.mp4
                let list = []
                for (let vv of this.queryFurnitureLibraryObj2.content) {
                    list.push(this.$oucy.ossUrlPrefixVideo +'/'+ vv.itemUrl)
                }
                this.$refs.videoPlay.openVideoPlayList(list, i)
            }
        },
    }
}
</script>
<style scoped lang="less">
.video {
    background: #F8F8F8;
    // padding-bottom:50px
}

.com_card,
.store_kind,
.customer_service,
.sales_rank,
.videoRight {
    background: #fff;
}

.price {
    width: 510px;
    height: 105px;
    background: #F8F8F8;
    margin-top: 20px;
    margin-left: 10px;
}

.price p {
    width: 28px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-left: 15px;
}



.store_kind {
    border-spacing: 0;
    width: 210px;
    /*height: 280px;*/
    // margin-top: 20px;
}

.store_kind th,
td {
    height: 40px;
    border: 1px solid #EAEAEA;
    text-align: center;
    line-height: 40px;
}

.store_kind th {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: #F8F8F8;
    color: #666666;
    text-align: center;
    line-height: 40px;
}

.store_kind .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
}

.customer_service {
    border-spacing: 0;
    width: 210px;
    // height: 280px;
    margin-top: 20px;
}

.customer_service th {
    font-family: Microsoft YaHei;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    background: #F8F8F8;
    color: #666666;
    border: 1px solid #EAEAEA;
    text-align: center;
    line-height: 40px;
}

.customer_service td {
    height: 60px;
    border: 1px solid #EAEAEA;
    // margin-top: 30px;
    text-align: center;
    line-height: 60px;
}

.customer_service img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    float: left;
}

.customer_service .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
}

.sales_rank {
    border-spacing: 0;
    width: 210px;
    margin-top: 20px;
    /*height: 1302px;*/
    background: #FFFFFF;
    border: 1px solid #EFDFCA;
    border-collapse: collapse;
    padding-bottom: 20px;
}

.sales_rank th {
    width: 210px;
    height: 48px;
    background: linear-gradient(0deg, #FFFDF8, #FFF6EA);
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.sales_rank td {
    border: none;
}

.goods_name {
    width: 188px;
    height: 40px;
    background: #FAFAFA;
    margin: 0 auto;
    position: relative;
    top: -8px;
}

.goods_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 40px;
}

.goods_name2 {
    width: 188px;
    height: 40px;
    background: #FAFAFA;
    margin: -5px auto;
}

.goods_name2 span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 40px;
}

.top {
    width: 820px;
    height: 32px;
    background: #F8F8F8;
    margin-left: 80px;
    margin-top: 20px;
}

.top p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 11px;
    text-align: center;
    float: left;
    margin-left: 22px;
}

.goods_condition {
    width: 650px;
    float: left;
    margin-left: 20px;
}

.goods_condition td {
    width: 140px;
    text-align: left;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    border: none;
}

.goods_comment /deep/ .el-tabs {
    width: 930px;
    margin-left: 20px;
}

.goods_comment /deep/ .el-tabs__item {
    width: 140px;
    height: 39px;
    background: #F3F3F3;
    border: none;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.goods_comment /deep/ .el-tabs__item.is-active {
    background: #2090FF;
    color: white;
}

.goods_comment /deep/ .el-tabs__nav-wrap {
    height: 41px;
    background: #2090FF;
}

.goods_comment /deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none;
}

.goods_comment /deep/ .el-tabs__nav-scroll {
    background-color: #F8F8F8;
}

.goods_comment /deep/ .el-form-item__label {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    width: 10px;
}

/deep/ .el-divider {
    width: 820px;
    margin-left: 80px;
}

.vhtml /deep/ img {
    max-width: 100%;
}

.right_button {
    float: right;
    margin-right: 30px;
}

.right_button .el-button {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.right_button .el-button:focus {
    font-weight: bold;
    color: #2894FF !important;
}

.comment {
    float: right;
    width: 820px;
    margin-right: 30px;
}

.comment_1 {
    background: #F8F8F8;
    border-radius: 4px;
}

.comment_2 {
    background: #F8F8F8;
    border-radius: 4px;
    margin-top: 10px;
}

.comment_3 {
    background: #F8F8F8;
    border-radius: 4px;
    margin-top: 10px;
}

.comment_4 {
    background: #F8F8F8;
    border-radius: 4px;
    margin-top: 10px;
}

.comment_right {
    width: 760px;
    height: 112px;
    padding: 10px;
    text-align: left
}

.comment_right_p1 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.comment_right_p2 {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #AAAAAA;
    text-align: left;
    position: relative;
}

.comment_right span {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 52px;
}

.comment_img {
    width: 100%;
    margin-left: -10px;
}

.comment_img img {
    margin-left: 10px;
    float: left;
}

.comment_img img .active {
    border: 4px #2090FF solid;
}

.comment_reply {
    background-image: url("../../assets/background/reply_bk.png");
    background-repeat: no-repeat;
    padding: 30px;
    width: 665px;
}

.comment_reply span {
    float: left;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #5B6069;
    line-height: 20px;
    margin-left: -8px;
}

.comment_reply p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 4px;
}

.comment_reply img {
    margin-top: 0px;
    margin-left: 0px;
}

.username {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
}

.footer_footer {
    margin-top: 150px;
}

.footer_footer table {
    margin: 0px auto;
}

.footer_footer table td {
    border: none;
    text-align: left;
}

.footer_footer span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    padding: 10px;
}


.drawer_left_top {
    width: 220px;
    height: 64px;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_left {
    width: 220px;
    height: 935px;
    margin-top: -20px;
    background-color: #F4F4F4;
}

.product_name ul li:hover {
    background: #FFFFFF;
}

.product_name ul li {
    width: 180px;
    height: 96px;
    list-style: none;
    cursor: pointer;
    margin-left: -40px;
    padding: 20px 20px;
}

.product_name span {
    line-height: 30px;
}

.product_condition {
    text-align: left;
    margin-top: -15px;
}

.product_condition p {
    width: 207px;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.product_condition .span1 {
    width: 132px;
    height: 12px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
}

.product_condition .span2 {
    width: 63px;
    height: 12px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ED6732;
}

/deep/ .el-input-number__inner {
    width: 20px;
}

.fangan_name {
    height: 18px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    float: left;
    padding: 20px;
}

/*头部*/
.topNav {
    background: #fff;
    padding: 10px 0;
}

.df-jc-s-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 58px;
    width: 163px;
}

.input-container {
    width: 500px;
    /*margin: 0 auto;*/
}

.topCenter {
    background: url('../../assets/images/product/vipTopBG.png');
    background-size: 100% 100%;
}

.topCenterBody {
    height: 261px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: left;

}

.merchantLogo {
    width: 128px;
    height: 128px;
    border-radius: 3px;
}

.merchantText {
    flex: 1;
}

.topBottom {
    width: 100%;
    background: #fff;
}

.breadcrumbBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.z-skus {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.z-sku {
    width: 30%;
    padding-right: 10px;
    box-sizing: border-box;
    margin-right: 10px;
}

.lisColor {
    box-sizing: border-box;
    border: 3px solid #2090FF;
}

.notlisColor {
    box-sizing: border-box;
    border: 3px solid #fff;

}

/*头部*/

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.product.topBottom .el-menu.el-menu--horizontal {
    border: none;
    /*font-weight: bold;*/
    font-size: 16px;
    color: #333;
}

.product .el-menu-item {
    font-size: 16px;
}

.product .el-menu--horizontal>.el-menu-item {

    color: #333;
}

.product .el-menu--horizontal>.el-submenu .el-submenu__title {

    font-size: 16px;
    color: #333;
}




.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu-item {
    /*margin-left:20px;*/
    z-index: 1000;
}



.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 0px;*/
    /*display: inline-block;*/
    /*padding: 20px;*/
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.auth {
    display: flex;
    margin-bottom: 30px;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
}

.auth .el-button--text.select {
    color: #409EFF;
}

.el-button--text:hover {
    /*color: rgb(32,144,255) !important;*/
}

.el-button--mini {
    border: none;
}

.el-button--mini:hover {
    /*background-color: rgb(32,144,255) !important;*/
}

/*.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}*/

.middle_middle {
    height: 52px;
    background-color: #FFFFFF;
    margin: 10px 0 10px 10px;
}

.middle_middle span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
}

.middle_middle .el-input {
    width: 97px;
    margin-right: 10px;
}

.list .el-table__row>td {
    /*去除表格线*/
    border: none;
}

.list .el-table th.is-leaf {
    /*去除上边框*/
    border: none;
}

.list .el-table::before {
    /*去除下边框*/
    height: 0;
}

.wangge {
    float: right;
    margin-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
}



.middle ul li:nth-child(4n) {
    margin-right: 0px;
}

.middle ul {
    text-align: left;
}

.middle ul li {
    width: 170px;
    height: 340px;
    background-color: #FFFFFF;
    // padding: 27px;
    /*float: left;*/
    display: inline-block;
    list-style: none;
    cursor: pointer;
}

.title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #111111;
    line-height: 1.2;
}

.brand_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    // float: left;
    margin-right: 5px;
}

.price {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E92121;
    line-height: 50px;
}

.stock {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 50px;
}

.add .el-button--text {
    // box-sizing:border-box;
    width: 100%;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    margin-top: 15px;
    line-height: 0px;
}

.pagination {
    margin-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 40px;
    // line-height: 150px;
}

.liebiao {
    min-height: 500px;
}

.liebiao ul li {
    width: 850px;
    height: 100px;
    background-color: #FFFFFF;
    list-style: none;
    margin-left: -20px;
}

table {
    text-align: left;
    // margin-top: 15px;
}

.liebiao .el-button--text {
    width: 90px;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    line-height: 0px;
}

.grid {
    /*min-height: 500px;*/
}

.grid ul {
    // margin-left: -25px;

}

.el-page-header__title {
    background-color: red;
}

.wangge {
    float: right;
    margin: 15px 30px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
}

.brand_name {
    text-align: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    /*float: left;*/
    height: 15px;
}


.com_card {
    background-image: url("../../assets/goods/background.png");
    width: 210px;
    height: 150px;
    /*margin-top: 65px;*/
}

.com_card p {
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    text-align: center;
    margin: 0 auto;
}

.com_card img {
    padding: 10px;
    margin-top: 10px;
}

/*/deep/ .btn-next{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .btn-prev{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .el-pager{position: relative;top: 9px;}
/deep/ .number{background: #FFFFFF!important;}
/deep/ .active{background:#2090ff!important ;}*/
.classifyIcon {
    width: 15px;
    height: 15px;
}

.spuImgs {
    width: 219px;
    height: 219px;
    background-color: #f1f1f1;
}

.spuImgs2 {
    width: 96px;
    height: 96px;
    background-color: #f1f1f1;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

.el-menu-item,
.product /deep/ .el-submenu__title {
    font-size: 16px;
    color: #333;
}

.carousel img {
    /*设置图片宽度和浏览器宽度一致*/
    width: 100%;
    height: inherit;
}

.headline {
    text-align: center;
    margin-top: 10px;
    padding-top: 40px;
    font-size: 24px;
    font-weight: bold;
    color: #323A4A;
    color: #323A4A;
}

.headlinetext {
    color: #777E8C;
    font-size: 12px;
}

.headline_line {
    display: inline-block;
    width: 24px;
    height: 6px;
    background: #323A4A;
    border-radius: 2px;
    position: relative;
    top: -10px;
}

.centerNav {
    padding: 15px 0;
}

.nav {
    cursor: pointer;
    flex: 1;
    text-align: center;

    span {
        margin: 0 auto;
        width: 30px;
        height: 6px;
        background: #323A4A;
        border-radius: 2px;
        position: relative;
        display: none;
        top: 16px;
    }

    &.selsct {
        span {
            display: block;
        }

    }
}

.right_top {
    display: flex;
    background: #fff;
    padding: 20px;

    .inputprice {
        width: 76px;
    }
}

.store_kind .el-button.selsct {
    /*    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;*/
    color: #2090FF;
}

.line-h {
    display: inline-block;
    width: 5px;
    height: 16px;
    background: #2090FF;
    border-radius: 1px;
    position: relative;
    top: 2px;
}


// 主推产品
.recommendSpu,
.hotSpu {
    // width: 219px;
    display: flex;
}

.recommendSpu_item1 {
    width: 464px;
    height: 350px;
    margin-right: 30px;
}

.recommendSpu_item {
    margin: 0 13px;
}

.recommendSpu_item,
.hotSpu_item,
.newSpu_item {
    width: 219px;
    background: #fff;
    height: 350px;
}

.hotSpu {
    flex-wrap: wrap;
    // margin: 0 -13px;
}

.hotSpu_item,
.newSpu_item,
.navTypeSpu_item {
    width: 218px;
    margin-right: 26px;
    margin-top: 20px;
}

.navTypeSpu_item {
    width: 233px;

}

.newSpu {
    display: flex;
}

.navTypeSpu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.alignRight {
    // display: flex;
    flex-direction: row-reverse;
}

.topTabsBody {
    background: #323A4A;
    color: #B4B9C5;
}

.topTabs {
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    justify-content: center;
    height: 40px;
}

.topTab {
    text-align: center;
    justify-content: center;
    flex: 1;

    img {
        margin-right: 20px;
    }

    &:hover {
        background: #384154;
        color: #fff;
    }

    &.select {
        background: #384154;
        border-bottom: 6px solid #2090FF;
    }
}

.hotSaleProducts {
    justify-content: space-between;
    // background:#030;
    width: 1280px;

}

.hotSaleProduct {
    width: 633px;
    position: relative;
    margin-bottom: 10px;

}

.hotSaleProduct+.hotSaleProduct {
    margin-left: 20px;
}

.hotSaleProduct_img {
    width: 100%;
}

.hotSaleProducts_btm {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    padding: 6px 10px;
    box-sizing: border-box;
}

.navTypeSpu_item:nth-child(4n) {
    margin-right: 0;
}

.enterpriseShopNamedown {
    background: #363E4F;
    padding: 4px;
}

// 认证档案
.Certifications {

    .Certification-top {
        display: flex;

        .box {
            text-align: center;
            flex: 1;
            background: #F6F7F8;
            border-radius: 2px;
            margin: 5px;
            padding: 20px;

            .box-top {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2090FF;
            }

            .box-bottom {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #555C68;
                margin-top: 25px;
            }
        }
    }

    .tabTitle {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #AAAAAA;
    }

    .tag {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #555C68;
        background: #F6F7F8;
        border-radius: 1px;
        display: inline-block;
        padding: 2px 6px;
        margin-left: 10px;
    }
}

.navTypeSpu_item /deep/ .spuImgs {
    width: 233px;
    height: 233px;
}

.list4 /deep/ .spuImgs {
    width: 305px;
    height: 305px;
}

.list5 /deep/ .spuImgs {
    width: 240px;
    height: 240px;
}

.itemCover {
    width: 100%;
    height: 215px;
    position: relative;
    overflow: hidden;

    .bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .7);
        display: none;
        // transform: translate(-50%, -50%);
    }


    .el-icon-video-play {
        font-size: 50px;
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        display: none;
    }

    &:hover .bg {
        display: inline-block;
    }

    &:hover .el-icon-video-play {
        display: inline-block;
    }
}

.boxs {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    padding: 24px;
}

.box {
    width: 25%;
    box-sizing: border-box;
    padding: 0 10px 20px;
}

.item {
    background: #F6F6F6;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;

    .itemInfo {
        box-sizing: border-box;
        height: 85px;
        padding: 30px 10px 40px;
    }
}

.videoAlbumName {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    padding: 15px 0;
    border-bottom: solid 4px #f8f8f8;
}
</style>