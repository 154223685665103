<!-- 直播播放 -->
<template>
    <div class="videoPlay" v-if="show">
        <div class="videobox" v-if="list && list.length">
            <el-carousel indicator-position="outside" :initial-index="initialIndex" height="500px" :autoplay="false" :loop="false" @change="change">
                <el-carousel-item v-for="(item,index) in list" class="item" :key="index">
                    <video :src="item" preload="auto" controls class="video1" :id="'video_'+index" :ref="'video_'+index"></video>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div v-else>
            <video style="max-width: 80%;max-height: 80%;" :src="url" autoplay preload="auto" controls></video>
        </div>
        <i class="el-icon-circle-close pointer" @click="close" title="关闭"></i>
    </div>
    </div>
</template>
<script>
export default {

    name: "videoPlay",

    data() {
        return {
            url: null,
            show: false,
            list: [],
            initialIndex: 0,
            operation_p_page_last_start_time: 0,

        }
    },
    computed: {

    },
    mounted() {
        document.addEventListener("keydown", (keydown) => {
            switch (event.keyCode) {
                case 27:
                    this.close()
                    break;
            }
        });

    },
    methods: {
        openVideoPlay(url) {
            this.url = url
            this.show = true
            this.operation_p_page_last_start_time = new Date().getTime()
        },
        openVideoPlayList(list, initialIndex) {
            this.list = list
            this.initialIndex = initialIndex
            this.show = true
            this.operation_p_page_last_start_time = new Date().getTime()
            setTimeout(() => {
                var x = document.getElementById('video_' + initialIndex)
                if (x) x.play()
            })
        },
        change(e) {
            this.initialIndex = e
            console.log(e)
            for (var i = 0; i < this.list.length; i++) {
                var x = document.getElementById('video_' + i)
                if (x) x.pause()
            }
            var x = document.getElementById('video_' + e)
            if (x) x.play()
        },
        close() {
            this.show = false
            this.$oucy.recordPageAction({
                'operation_p_page_last_start_time': this.operation_p_page_last_start_time,
                operation_p_page_radar_view_type: 5,
                operation_p_page_radar_object_id: this.$oucy.getQueryVariable('enterpriseId'),
            },false)
        }

    },
    beforeDestroy() {
        this.show && this.close()
    }
}
</script>
<style scoped lang="less">
.videobox {
    flex: 1;
    text-align: center;

}

.video1 {
    max-height: 100%;
    max-width: 100%;
}

.item {
    /*width: 1000px;*/
    height: 500px;
}

.videoPlay {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: rgba(0, 0, 0, .7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-icon-circle-close {

    position: absolute;
    z-index: 99;
    top: 10px;
    right: 10px;
    font-size: 40px;
    color: #fff;
}
</style>